
function makeMulti(fn) {
    // 转换函数为字符串
    let fnString = fn.toString();
    // 提取位于 /* 和 */ 之间的内容
    let artString = fnString.substring(fnString.indexOf("/*") + 2, fnString.lastIndexOf("*/"));
    return artString;
}

function string() {
    /*
          ___           ___           ___           ___           ___     
         /  /\         /  /\         /  /\         /  /\         /  /\    
        /  /::\       /  /::\       /  /::\       /  /::\       /  /::|   
       /  /:/\:\     /  /:/\:\     /  /:/\:\     /  /:/\:\     /  /:|:|   
      /  /::\ \:\   /  /:/  \:\   /  /:/  \:\   /  /:/  \:\   /  /:/|:|__ 
     /__/:/\:\_\:\ /__/:/ \__\:| /__/:/ \__\:| /__/:/_\_ \:\ /__/:/_|::::\
     \__\/  \:\/:/ \  \:\ /  /:/ \  \:\ /  /:/ \  \:\__/\_\/ \__\/  /~~/:/
          \__\::/   \  \:\  /:/   \  \:\  /:/   \  \:\ \:\         /  /:/ 
          /  /:/     \  \:\/:/     \  \:\/:/     \  \:\/:/        /  /:/  
         /__/:/       \__\::/       \__\::/       \  \::/        /__/:/   
         \__\/            ~~            ~~         \__\/         \__\/    
     
    
     */
}

// 使用ESLint的注释来临时关闭no-console规则
/* eslint-disable no-console */
console.log(makeMulti(string));
/* eslint-enable no-console */